import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import DDNSCards from 'components/Internet_Access/DDNS_Provider/DDNSCards';
import NavButtons from 'components/Internet_Access/NavButtons';
import ForumBox from 'components/Internet_Access/DDNS_Provider/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "DDNS Provider",
  "path": "/Internet_Access/DDNS_Provider/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Use a Third Party DDNS Provider with your INSTAR IP Camera",
  "image": "./IA_SearchThumb_ThirdPartyDDNS.png",
  "social": "/images/Search/IA_SearchThumb_ThirdPartyDDNS.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_IA-DDNS_white.webp",
  "chapter": "Remote Access"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='DDNS Provider' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use a Third Party DDNS Provider with your INSTAR IP Camera' image='/images/Search/IA_SearchThumb_ThirdPartyDDNS.png' twitter='/images/Search/IA_SearchThumb_ThirdPartyDDNS.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Fernzugriff/DDNS_Anbieter/' locationFR='/fr/Internet_Access/DDNS_Provider/' crumbLabel="DDNS Provider" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "ddns-provider",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#ddns-provider",
        "aria-label": "ddns provider permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DDNS Provider`}</h1>
    <h2 {...{
      "id": "use-a-third-party-ddns-services-with-your-instar-ip-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#use-a-third-party-ddns-services-with-your-instar-ip-camera",
        "aria-label": "use a third party ddns services with your instar ip camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use a Third Party DDNS services with your INSTAR IP Camera`}</h2>
    <h3 {...{
      "id": "what-is-this-ddns-all-about",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-is-this-ddns-all-about",
        "aria-label": "what is this ddns all about permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is this DDNS all about?`}</h3>
    <p>{`Why do we hide the real addresses of our websites behind domain names? Firstly, the IP addresses are a bit difficult to memorize for organic brains like ours. And secondly, the IP address of a web site might change over time. The latter is a smaller problem for Google - they pay for the IP addresses and they will keep them for a while - but it is a big problem if you want to use an IP camera at home (or in general a local webserver for that matter). Your router is assigned a WAN IP address (Wide Area Network) by your internet services provider. This is your telephone number that allows you to call home when you are at work. You can check your personal WAN IP by opening a web site like `}<a parentName="p" {...{
        "href": "http://wieistmeineip.de/"
      }}>{`wieistmeineip.de`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e299dc63c6f8f66b08adb657614e3164/0b533/ThirdpartyDDNS_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAABDElEQVQY032PzWqDUBCFJ0ZwkQjiz9WgqLeKuHFjBSEkJc3OLiRPUeguC1ca6/WnxQv1UfqOJV4KWbT5Fodh5gxnBiil4zhO00Qpbdu273tCSFmWVVXVdV1fLlVVtd213/f9MEOG4et8/i4KCILA87wwDDHGmqapqqrrOkJIkiRRFCVJUhRFFEWmi5nlcgkARVmCbdthGLquizH2fR8hJMuyIAgAsLiB4zj4hdXvdQ2GYSiKwqJuHXdgtqZpwDRNY7OxHUfVNJ7nWSBT7h+Y7bq8Xq2eEHpxnMyyHixrPT/G8/ydK9iIEALb3e7tePzI88/TieT5a5Zt9/vnwyFN0ziOH/8iSZIoirqu+wHYJUC3pyeU9gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e299dc63c6f8f66b08adb657614e3164/e4706/ThirdpartyDDNS_01.avif 230w", "/en/static/e299dc63c6f8f66b08adb657614e3164/d1af7/ThirdpartyDDNS_01.avif 460w", "/en/static/e299dc63c6f8f66b08adb657614e3164/df5cf/ThirdpartyDDNS_01.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e299dc63c6f8f66b08adb657614e3164/a0b58/ThirdpartyDDNS_01.webp 230w", "/en/static/e299dc63c6f8f66b08adb657614e3164/bc10c/ThirdpartyDDNS_01.webp 460w", "/en/static/e299dc63c6f8f66b08adb657614e3164/b0a15/ThirdpartyDDNS_01.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e299dc63c6f8f66b08adb657614e3164/81c8e/ThirdpartyDDNS_01.png 230w", "/en/static/e299dc63c6f8f66b08adb657614e3164/08a84/ThirdpartyDDNS_01.png 460w", "/en/static/e299dc63c6f8f66b08adb657614e3164/0b533/ThirdpartyDDNS_01.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e299dc63c6f8f66b08adb657614e3164/0b533/ThirdpartyDDNS_01.png",
              "alt": "Portforwarding Vodafone EasyBox",
              "title": "Portforwarding Vodafone EasyBox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can check the IP address of your websites yourself, by typing nslookup into your command prompt and then the web domain you want to check. In this case we found that one of the IP address of google.com is 173.194.127.160.`}</p>
    <p>{`So if checking your WAN IP address is so simple, why not write it down and just use it to connect to your camera? The problem is, that your WAN IP address usually changes every 24hrs. You can usually buy static IP addresses from your provider - but if you don´t want to spend extra money on that, you would have to check your WAN IP address every morning before leaving your house. Having a static domain name that is automatically updated with the newest WAN IP address of your router every time the address changes starts to sound tempting?`}</p>
    <h3 {...{
      "id": "why-would-i-want-to-have-a-third-party-ddns-address",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#why-would-i-want-to-have-a-third-party-ddns-address",
        "aria-label": "why would i want to have a third party ddns address permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why would I want to have a Third Party DDNS Address?`}</h3>
    <p>{`That is why we already installed a DDNS address on your INSTAR camera. You can always use this service to connect to your camera from the internet (IF you have a port forwarding set up inside your router!). So why would you want to have a third party DDNS address running to connect to your camera?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e38d7e19c620a0c7e6310ffa9d81d22b/0b533/ThirdpartyDDNS_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABJ0lEQVQoz43OzWoCMRDA8X1Nz76ETyPqxZvV1ksPjRZ6cBVdCxV1J5+YhsTNbjauwWtrhUIRqT9mbvOHiZrN5uPTU7/f7/V6D/cZDAb1er1Wq0WTeCo4S5bvReFCCMf/VFV1Op2Gw2Gj0YieX8aw3cTxlHOhlPq8g5TycDh0u90Ivb6F6sgoZ5QxwiimjHBKaZZlzrnihjzPW61WNBqNfekBgDFGMBFccMa01peLa9baoii895d4pLWOJ5NksZjNZkmyXMznKYCUUt0gpczzvN1uRwgha+1qtcIYMKQAkKawWa8JocYYfcUYs9vtsizrdDrnOISglNLaXOZnzW9p/tJa7/d759z5bYSQL/3mY0uAUExxiimmBFPBBWyJYMJaW7rSXfmOvwDkWsx7CE7vJQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e38d7e19c620a0c7e6310ffa9d81d22b/e4706/ThirdpartyDDNS_02.avif 230w", "/en/static/e38d7e19c620a0c7e6310ffa9d81d22b/d1af7/ThirdpartyDDNS_02.avif 460w", "/en/static/e38d7e19c620a0c7e6310ffa9d81d22b/df5cf/ThirdpartyDDNS_02.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e38d7e19c620a0c7e6310ffa9d81d22b/a0b58/ThirdpartyDDNS_02.webp 230w", "/en/static/e38d7e19c620a0c7e6310ffa9d81d22b/bc10c/ThirdpartyDDNS_02.webp 460w", "/en/static/e38d7e19c620a0c7e6310ffa9d81d22b/b0a15/ThirdpartyDDNS_02.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e38d7e19c620a0c7e6310ffa9d81d22b/81c8e/ThirdpartyDDNS_02.png 230w", "/en/static/e38d7e19c620a0c7e6310ffa9d81d22b/08a84/ThirdpartyDDNS_02.png 460w", "/en/static/e38d7e19c620a0c7e6310ffa9d81d22b/0b533/ThirdpartyDDNS_02.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e38d7e19c620a0c7e6310ffa9d81d22b/0b533/ThirdpartyDDNS_02.png",
              "alt": "Portforwarding Vodafone EasyBox",
              "title": "Portforwarding Vodafone EasyBox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Every INSTAR camera comes with a preinstalled DDNS address so you don´t have to worry about the things we talk about here.`}</p>
    <p>{`In the INSTAR DDNS address seen above instar01 is your cameras personal host, the ddns3-instar.de is the domain of our DDNS server and the complete address instar01.ddns3-instar.de is called the hostname of your address. For security reasons, the host part installed on your camera is generated randomly and cannot be traced back to your purchase. And the domain name is given by the server your camera is registered to. This means you don´t have any control over your camera´s hostname. We already try to make them as easy to remember as possible - but maybe you just want to have a personalize address? Or being able to access your camera at any time is very important and you are looking for redundancy? That is where third party supplier will come in handy.`}</p>
    <p>{`So just hop to a website like `}<a parentName="p" {...{
        "href": "http://www.noip.com"
      }}>{`www.noip.com`}</a>{`, create an account and install it on your camera? Well yes, that is possible. But the interesting part is that you don´t have to deactivate your INSTAR DDNS address in your camera to use a third party one. You can set it up inside your router, on a webserver that is running all the time anyway or on the surveillance station (QNAP or Synology) you use with your camera. This way you can have a personalized, and easy to remember, hostname - but still have your INSTAR address as a fall back option. And yes, you heard right! You just need one DDNS address, that is either maintained by your camera or your router, to connect to any device inside your local network. As we discussed earlier, the DDNS hostname is fancy way of writing down your routers WAN IP address. So, even if you have 5 INSTAR cameras running in your network, every single one of those DDNS addresses will just lead you to your router. The router then decides, based on the port the request is coming from, which cameras should be called. So basically, you only have to memorize 1 DDNS address and vary the port to call different cameras:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Camera 1`}</strong>{`: instar01.ddns3-instar.de:8081`}</li>
      <li parentName="ul"><strong parentName="li">{`Camera 2`}</strong>{`: instar01.ddns3-instar.de:8082`}</li>
      <li parentName="ul"><strong parentName="li">{`Camera 3`}</strong>{`: instar01.ddns3-instar.de:8083`}</li>
    </ul>
    <p>{`For information on how to forward these ports and why you should choose ports above 8080, please read on in our port forwarding article that will walk you through the setup for a couple of standard internet routers. In the following we will set up 3 different third party DDNS addresses on different systems. You will see that setting up the hostname and using it with an update service on your router or any other system is very easy and the steps are always the same - no matter what provider and hardware you plan to use. We choose those three providers because they offer `}<strong parentName="p">{`free hostnames`}</strong>{` for you to experiment with.`}</p>
    <h2 {...{
      "id": "list-of-thirdparty-ddns-service-provider",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#list-of-thirdparty-ddns-service-provider",
        "aria-label": "list of thirdparty ddns service provider permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`List of Thirdparty DDNS Service Provider`}</h2>
    <DDNSCards mdxType="DDNSCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      